import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fe9f3a00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "BgzlContent" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_3 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_4 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_5 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_6 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_7 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_8 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_9 = {
  key: 0,
  style: {"margin-left":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_EditTableItem = _resolveComponent("EditTableItem")!
  const _component_EditTable = _resolveComponent("EditTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 2
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "合同号码",
                      prop: "bghthm"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[0] || (_cache[0] = e => _ctx.form.bghthm = _ctx.valid(e)),
                          modelValue: _ctx.form.bghthm,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.bghthm) = $event)),
                          placeholder: "请输入合同号码",
                          maxlength: "25",
                          disabled: _ctx.disabled,
                          clearable: ""
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "日期",
                      prop: "date"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.date,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.date) = $event)),
                          placeholder: "请选择日期",
                          disabled: _ctx.disabled
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.form.type==1 || _ctx.form.type==2)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "发票号码",
                          prop: "fphm"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              onInput: _cache[3] || (_cache[3] = e => _ctx.form.fphm = _ctx.valid(e)),
                              modelValue: _ctx.form.fphm,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.fphm) = $event)),
                              placeholder: "请输入发票号码",
                              maxlength: "25",
                              disabled: _ctx.disabled,
                              clearable: ""
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==2)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "启运港",
                          prop: "qyg"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              onInput: _cache[5] || (_cache[5] = e => _ctx.form.qyg = _ctx.valid(e)),
                              modelValue: _ctx.form.qyg,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.qyg) = $event)),
                              placeholder: "请输入启运港",
                              maxlength: "100",
                              disabled: _ctx.disabled,
                              clearable: ""
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "目的港",
                          prop: "mdg"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              onInput: _cache[7] || (_cache[7] = e => _ctx.form.mdg = _ctx.valid(e)),
                              modelValue: _ctx.form.mdg,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.mdg) = $event)),
                              placeholder: "请输入目的港",
                              maxlength: "100",
                              disabled: _ctx.disabled,
                              clearable: ""
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==2)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "接货地",
                          prop: "jhd"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              onInput: _cache[9] || (_cache[9] = e => _ctx.form.jhd = _ctx.valid(e)),
                              modelValue: _ctx.form.jhd,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.jhd) = $event)),
                              placeholder: "请输入接货地",
                              maxlength: "100",
                              disabled: _ctx.disabled,
                              clearable: ""
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==0)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "保险",
                          prop: "bx"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              onInput: _cache[11] || (_cache[11] = e => _ctx.form.bx = _ctx.valid(e)),
                              modelValue: _ctx.form.bx,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.bx) = $event)),
                              placeholder: "请输入保险",
                              maxlength: "150",
                              disabled: _ctx.disabled,
                              clearable: ""
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "包装",
                          prop: "bz"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              onInput: _cache[13] || (_cache[13] = e => _ctx.form.bz = _ctx.valid(e)),
                              modelValue: _ctx.form.bz,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.bz) = $event)),
                              placeholder: "请输入包装",
                              maxlength: "150",
                              disabled: _ctx.disabled,
                              clearable: ""
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==0)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "交货期限",
                          prop: "jhqx"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: _ctx.form.jhqx,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.jhqx) = $event)),
                              disabled: true
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==0)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 5 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "价格条款",
                          prop: "jgtk"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: _ctx.form.jgtk,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.jgtk) = $event)),
                              disabled: true
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==0)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "付款条款",
                          prop: "fktk"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: _ctx.form.fktk,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.fktk) = $event)),
                              disabled: true
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_EditTable, _mergeProps({
      ref: el=>_ctx.refMap.set('bgzlContentDetail',el)
    }, _ctx.cardDetailParam('bgzlContentDetail'), {
      beganEdit: _ctx.beganEdit,
      beforeEndEdit: _ctx.beforeEndEdit
    }), {
      tbCols: _withCtx(() => [
        _createVNode(_component_EditTableItem, {
          ownerInst: this,
          editTableItems: _ctx.compParams.detailInfos.bgzlContentDetail.columns,
          refMap: _ctx.refMap
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: "货物描述",
              prop: "F_HWMS"
            }, {
              default: _withCtx((scope) => [
                (!scope.row.EDIT)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.row.F_HWMS), 1))
                  : (_openBlock(), _createBlock(_component_el_input, {
                      key: 1,
                      placeholder: "请输入货物描述",
                      modelValue: scope.row.F_HWMS,
                      "onUpdate:modelValue": ($event: any) => ((scope.row.F_HWMS) = $event),
                      clearable: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "数量",
              prop: "F_NUM"
            }, {
              default: _withCtx((scope) => [
                (!scope.row.EDIT)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.F_NUM), 1))
                  : (_openBlock(), _createBlock(_component_el_input_number, {
                      key: 1,
                      modelValue: scope.row.F_NUM,
                      "onUpdate:modelValue": ($event: any) => ((scope.row.F_NUM) = $event),
                      min: 0,
                      max: 9999999,
                      style: {"width":"100%"},
                      precision: 0,
                      controls: false
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "单价",
              prop: "F_PRICE"
            }, {
              default: _withCtx((scope) => [
                (!scope.row.EDIT)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row.F_PRICE), 1))
                  : (_openBlock(), _createBlock(_component_el_input_number, {
                      key: 1,
                      modelValue: scope.row.F_PRICE,
                      "onUpdate:modelValue": ($event: any) => ((scope.row.F_PRICE) = $event),
                      min: 0,
                      max: 9999999,
                      style: {"width":"100%"},
                      precision: 2,
                      controls: false
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "金额",
              prop: "F_AMOUNT"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(_ctx.utils.UtilPub.toAmountFormat(_ctx.rowAmount(scope.row),2)), 1)
              ]),
              _: 1
            }),
            (_ctx.form.type==1)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 0,
                  label: "件数",
                  prop: "F_JS"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(scope.row.F_JS), 1))
                      : (_openBlock(), _createBlock(_component_el_input_number, {
                          key: 1,
                          modelValue: scope.row.F_JS,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_JS) = $event),
                          min: 0,
                          max: 9999999,
                          style: {"width":"100%"},
                          precision: 0,
                          controls: false
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==1)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 1,
                  label: "包装",
                  prop: "F_BZ"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(scope.row.F_BZ), 1))
                      : (_openBlock(), _createBlock(_component_el_input, {
                          key: 1,
                          placeholder: "请输入包装",
                          modelValue: scope.row.F_BZ,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_BZ) = $event),
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==1)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 2,
                  label: "毛重",
                  prop: "F_MZ"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(scope.row.F_MZ), 1))
                      : (_openBlock(), _createBlock(_component_el_input_number, {
                          key: 1,
                          modelValue: scope.row.F_MZ,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_MZ) = $event),
                          min: 0,
                          max: 9999999,
                          style: {"width":"100%"},
                          precision: 2,
                          controls: false
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==1)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 3,
                  label: "净重",
                  prop: "F_JZ"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(scope.row.F_JZ), 1))
                      : (_openBlock(), _createBlock(_component_el_input_number, {
                          key: 1,
                          modelValue: scope.row.F_JZ,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_JZ) = $event),
                          min: 0,
                          max: 9999999,
                          style: {"width":"100%"},
                          precision: 2,
                          controls: false
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.form.type==1)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 4,
                  label: "体积",
                  prop: "F_TJ"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(scope.row.F_TJ), 1))
                      : (_openBlock(), _createBlock(_component_el_input_number, {
                          key: 1,
                          modelValue: scope.row.F_TJ,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_TJ) = $event),
                          min: 0,
                          max: 9999999,
                          style: {"width":"100%"},
                          precision: 2,
                          controls: false
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["editTableItems", "refMap"])
      ]),
      _: 1
    }, 16, ["beganEdit", "beforeEndEdit"])
  ]))
}