import BaseBean from "@/utils/BaseBean";

export interface IBgzlListDataObj {
    utilInst:BgzlListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}
export default class BgzlListUtil extends BaseBean{
    public dataObj:IBgzlListDataObj;

    constructor(proxy:any,dataObj:IBgzlListDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.OrderProviderApi.buildNetworkData();
    }
}