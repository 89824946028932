import BaseBean from "@/utils/BaseBean";
import BgzlContentCard from "@/views/project/order/fy/bgzl/content/BgzlContentCard.vue";
import HtxxdCard from "@/views/project/order/fy/zl/bgzl/htxxd/HtxxdCard.vue";
import XdxxdCard from "@/views/project/order/fy/zl/bgzl/xdxxd/XdxxdCard.vue";
import FpxxdCard from "@/views/project/order/fy/zl/bgzl/fpxxd/FpxxdCard.vue";
import _ from "lodash";

export interface IBgzlCardDataObj {
    utilInst:BgzlCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class BgzlCardUtil extends BaseBean{
    public dataObj:IBgzlCardDataObj;

    constructor(proxy:any,dataObj:IBgzlCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        this.dataObj.otherParams.qpData=await this.utils.OrderProviderApi.getAllQp({params:{queryType:1}});
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({params:{from:'bgzlCard'}});//查询所有客户
    }
    //打开合同信息单、箱单信息单、发票信息单的表单
    public async openForm(id:string,type:number,title:string):Promise<any>{
        await this.utils.UtilPub.openDialog({id:id,mainId:this.dataObj.form.id,proxy:this.proxy,modelComp:BgzlContentCard,ownerComp:this.proxy,
            type:type,title: title,
            cardFrom:'BgzlCard',overflow:'hidden',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }
    //打开合同信息单、箱单信息单、发票信息单的pdf
    public async openPdf(id:string,type:number,title:string):Promise<any>{
        let modelComp:any=HtxxdCard;
        if(type==1){
            modelComp=XdxxdCard;
        }else if(type==2){
            modelComp=FpxxdCard;
        }
        await this.utils.UtilPub.openDialog({id:id,proxy:this.proxy,addOrEdit:'/load',modelComp:modelComp,ownerComp:this.proxy,
            type:type,title: title,mainId:this.dataObj.form.mainId,
            cardFrom:'BgzlCard',overflow:'hidden',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }
    //处理勾选的合同信息单、箱单信息单、发票信息单的表单
    public dealSelXxd():void{
        //构建已勾选的信息单
        this.dataObj.form.yxht='';
        this.dataObj.form.yxxd='';
        this.dataObj.form.yxfp='';
        this.dataObj.otherParams.content.htxxd.forEach((item:any)=>{
            if(item.flag)this.dataObj.form.yxht=this.dataObj.form.yxht+item.id+','
        })
        this.dataObj.otherParams.content.xdxxd.forEach((item:any)=>{
            if(item.flag)this.dataObj.form.yxxd=this.dataObj.form.yxxd+item.id+','
        })
        this.dataObj.otherParams.content.fpxxd.forEach((item:any)=>{
            if(item.flag)this.dataObj.form.yxfp=this.dataObj.form.yxfp+item.id+','
        })
        if(this.dataObj.form.yxht.endsWith(','))this.dataObj.form.yxht=this.dataObj.form.yxht.substr(0,this.dataObj.form.yxht.length-1);
        if(this.dataObj.form.yxxd.endsWith(','))this.dataObj.form.yxxd=this.dataObj.form.yxxd.substr(0,this.dataObj.form.yxxd.length-1);
        if(this.dataObj.form.yxfp.endsWith(','))this.dataObj.form.yxfp=this.dataObj.form.yxfp.substr(0,this.dataObj.form.yxfp.length-1);
    }
    //提交之前检查
    public beforeSubmit():boolean{
        this.dealSelXxd();
        if(!this.dataObj.form.yxht || !this.dataObj.form.yxxd || !this.dataObj.form.yxfp){
            this.proxy.$message({type:'error',message:'每项信息单各自至少勾选一个才能提交'});
            return false;
        }
        if(this.dataObj.refMap.get('bgd').fileList.length==0 || this.dataObj.refMap.get('fxtzs').fileList.length==0) {
            this.proxy.$message({type:'error',message:'至少上传一份报关单和放行通知书才能提交'});
            return false;
        }
        return true;
    }
    //提交
    public async submit():Promise<void>{
        if(this.beforeSubmit()){
            this.utils.Tools.configBox({
                message:'确定提交吗?',
                sureFn:async ()=> {
                    this.dataObj.form.saveType='submit';
                    await this.proxy.engine.saveHandler();
                }
            });
        }
    }
}