import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import BgzlCardUtil ,{IBgzlCardDataObj} from './bgzlCardUtil';
export default defineComponent({
    name:'BgzlCard',
    title:'报关资料',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IBgzlCardDataObj=reactive<IBgzlCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/bgzl')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                content:{
                    htxxd:[],//合同信息单
                    xdxxd:[],//箱单信息单
                    fpxxd:[],//发票信息单
                },
                qpData:[],
                customerData:[],//客户下拉数据
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BgzlCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------

        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:any,selectId:string)=> {
            if('customerId'==selectId){
                //如果有选择客户，则为买家填入默认信息
                if(newValue && !dataObj.form.mjmc && !dataObj.form.lxdh && !dataObj.form.lxdz){
                    let item=dataObj.otherParams.customerData.find((item:any)=>item.value==newValue);
                    if(item){
                        dataObj.form.mjmc=item.item.name;
                        dataObj.form.lxdh=item.item.contact;
                        dataObj.form.lxdz=item.item.contactAddress;
                    }
                }
            }
        }
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.form.id,
                    fileSize:80,
                    accept:'.pdf',
                    showFileList:true,
                    disabled:dataObj.disabled,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            //先清空各信息单
            dataObj.otherParams.content={
                htxxd:[],
                xdxxd:[],
                fpxxd:[]
            }
            res.data.list.forEach((item:any)=>{
                let flag:boolean=false;
                switch (item.type) {
                    case 0://合同信息单
                        if(dataObj.form.yxht && dataObj.form.yxht.indexOf(item.id)!=-1)flag=true;
                        // let index0=dataObj.otherParams.content.htxxd.length+1;
                        // dataObj.otherParams.content.htxxd.push({id:item.id,name:'报关合同-'+index0+'.pdf',flag: flag});
                        dataObj.otherParams.content.htxxd.push({id:item.id,name:item.bghthm+'.pdf',flag: flag});
                        break;
                    case 1://箱单信息单
                        if(dataObj.form.yxxd && dataObj.form.yxxd.indexOf(item.id)!=-1)flag=true;
                        // let index1=dataObj.otherParams.content.xdxxd.length+1;
                        // dataObj.otherParams.content.xdxxd.push({id:item.id,name:'箱单-'+index1+'.pdf',flag: flag});
                        dataObj.otherParams.content.xdxxd.push({id:item.id,name:item.bghthm+'.pdf',flag: flag});
                        break;
                    case 2://发票信息单
                        if(dataObj.form.yxfp && dataObj.form.yxfp.indexOf(item.id)!=-1)flag=true;
                        // let index2=dataObj.otherParams.content.fpxxd.length+1;
                        // dataObj.otherParams.content.fpxxd.push({id:item.id,name:'发票-'+index2+'.pdf',flag: flag});
                        dataObj.otherParams.content.fpxxd.push({id:item.id,name:item.bghthm+'.pdf',flag: flag});
                        break;
                }
            })
            if('/load'==addOrLoad){
                nextTick(()=>{
                    dataObj.refMap.get('ckxkz').uploadParams.belongMaxId=dataObj.form.id;
                    dataObj.refMap.get('bgd').uploadParams.belongMaxId=dataObj.form.id;
                    dataObj.refMap.get('fxtzs').uploadParams.belongMaxId=dataObj.form.id;
                })
                if(res.data.flag==1){
                    dataObj.disabled=true;
                    dataObj.refMap.get('bgDetail').setReadOnly(true);
                }
            }
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            dataObj.utilInst.dealSelXxd();
            return true;
        }
        //新增/修改信息单
        const openXxd=async (id:string,type:number)=>{
            switch (type) {
                case 0:await dataObj.utilInst.openForm(id,0,'合同信息单');break;
                case 1:await dataObj.utilInst.openForm(id,1,'箱单信息单');break;
                case 2:await dataObj.utilInst.openForm(id,2,'发票信息单');break;
            }
        }
        //查看pdf
        const pdfHandler=async (id:string,type:number)=>{
            switch (type) {
                case 0:await dataObj.utilInst.openPdf(id,0,'合同信息单');break;//新增合同信息单
                case 1:await dataObj.utilInst.openPdf(id,1,'箱单信息单');break;//新增箱单信息单
                case 2:await dataObj.utilInst.openPdf(id,2,'发票信息单');break;//新增发票信息单
            }
        }

        //提交
        const submitHandler=async ()=>{
            await dataObj.utilInst.submit();
        }


        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,buildUploadInitParams,handlePreview,beforeOpen,setParam,beforeSaveHandler
            ,openXxd,pdfHandler,submitHandler
        }
    }
});